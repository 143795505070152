import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '../../axios';
import { toast } from 'react-hot-toast';
import HashLoader from 'react-spinners/HashLoader';

const ProductCard = ({ product }) => {
  const [buttonText, setButtonText] = useState("Add to bag");
  const [isAdded, setIsAdded] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [addingToCart, setAddingToCart] = useState(false);

  // Calculate minimum variant prices
  const minPrices = product.variants.reduce((min, variant) => {
    if (!variant.is_active) return min;
    return {
      price: Math.min(min.price, variant.price),
      mrp: Math.min(min.mrp, variant.mrp)
    };
  }, { price: Infinity, mrp: Infinity });

  const addtocart = () => {
    setAddingToCart(true);
    //delete local storage
    localStorage.removeItem('cartCount');
    axiosInstance.post('/carts/add', {
      product_id: product.id,
      variant_id: product.variants[0].id,
      quantity,
    })
      .then(response => {
        console.log(response.data);
        setButtonText("Added to Cart");
        setIsAdded(true);
        toast.success('Added to cart successfully!');
        
        // Reset the button text after 3 seconds
        setTimeout(() => {
          setButtonText("Add to Bag");
          setIsAdded(false);
        }, 3000);
      })
      .catch(error => {
        console.error('Error adding to cart:', error);
        toast.error('Failed to add to cart');
      })
      .finally(() => {
        setAddingToCart(false);
      });
  };

  const increment = () => {
    setQuantity(prev => prev + 1);
  };

  const decrement = () => {
    setQuantity(prev => (prev > 1 ? prev - 1 : 1));
  };

  return (
    <div className="bg-white max-w-[100%] min-w-[100%] rounded-lg overflow-hidden shadow-xl mx-auto">
      <Link to={`/product/${product.id}`}>
        <div className="relative pb-[75%]">
          <img
            src={product?.images[0]}
            alt={product?.name}
            className="absolute top-0 left-0 w-full h-full object-cover"
          />
        </div>
        <div className="p-4 pt-2">
          <h3 className="text-lg text-[#86b84e] josefin-sans-heading font-semibold truncate">{product?.name}</h3>
          <p className="text-sm text-gray-500 whitespace-nowrap overflow-hidden text-ellipsis">{product?.description}</p>
          <div className="flex items-center gap-2 mt-2">
            <p className="font-bold">₹{minPrices.price}</p>
            {minPrices.mrp > minPrices.price && (
              <p className="text-gray-500 line-through">₹{minPrices.mrp}</p>
            )}
          </div>
        </div>
      </Link>
      <div className="flex flex-col items-start gap-2 p-4 pt-0">
        <div className="flex items-center gap-2">
          <p className="text-sm lg:block hidden">Quantity:</p>
          <div className="flex items-center justify-center bg-gray-300 rounded">
            <button onClick={decrement} className="h-full rounded-l px-2">-</button>
            <input
              type="number"
              className="w-20  bg-gray-100 p-1 text-center border-0 focus:outline-none"
              value={quantity}
              readOnly
            />
            <button onClick={increment} className="rounded-r px-2">+</button>
          </div>
        </div>
        <button
          onClick={addtocart}
          disabled={addingToCart}
          className="w-full bg-[#86b84e] josefin-sans-heading text-white py-2 rounded hover:bg-green-600 transition duration-200"
        >
          {addingToCart ? (
            <div className="flex items-center justify-center">
              <HashLoader color="#ffffff" size={24} />
            </div>
          ) : isAdded ? (
            <span>&#10003; {buttonText}</span>
          ) : (
            buttonText
          )}
        </button>
      </div>
    </div>
  );
};

export default ProductCard;
