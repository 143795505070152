import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../axios';
import logo from '../images/logo.png';
import { toast } from 'react-hot-toast';
const LoginPage = () => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      const response = await axiosInstance.post('/login', {
        phone: mobileNumber,
        otp: 123
      });

      if (response.status === 200) {
        // Store phone number in session storage for OTP verification
        sessionStorage.setItem('phoneNumber', mobileNumber);
        // navigate('/verify-otp');
        localStorage.setItem('user', JSON.stringify(response.data));
        localStorage.setItem('role', response.data.user.role);
        localStorage.setItem('username', response.data.user.name);
        if(response.data.user.role === "admin"){
          window.location.href = '/admin/products';
        }else{
          window.location.href = '/';
        }
        toast.success('Logged in successfully!');
        navigate('/');
      } else {
        setError('Invalid phone number or OTP. Please try again.');
        alert(response.data);
      }
    } catch (error) {
      setError('Error logging in. Please try again.');
      alert(error);

    //   console.error('Login error:', error);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center  py-12 px-4 sm:px-6 lg:px-8">
      <div className="absolute top-14">
        <img src={logo} alt="Logo"  />
      </div>
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Login to Your Account</h2>
        </div>
        <form onSubmit={handleSubmit} className="mt-8 space-y-6">
          <div>
            <label htmlFor="mobileNumber" className="block text-sm font-medium text-gray-700">
              Mobile Number
            </label>
            <input
              type="text"
              id="mobileNumber"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
              placeholder="Enter your mobile number"
              required
            />
          </div>
          
          {error && <p className="text-red-500 text-sm">{error}</p>}
          {success && <p className="text-green-500 text-sm">{success}</p>}
          
          <div>
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              Continue
            </button>
          </div>
        </form>
        
        <div className="text-center">
          <p className="text-sm text-gray-600">
            Don't have an account?{' '}
            <button
              onClick={() => navigate('/register')}
              className="font-medium text-green-600 hover:text-green-500"
            >
              Register
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;